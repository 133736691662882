.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.error {
  color: red;
  font-size: small;
  display: block;
  text-align: left;
}

.pointer {
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"][disabled] {
  cursor: no-drop;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"][disabled] {
  cursor: no-drop;
}

.resend_code button {
  color: #c33c42;
  font-size: 18px;
  font-weight: 700;
  padding-top: 12px;
  display: inline-block;
  background: none;
  border: none;
}

.PhoneInputCountryIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 0 transparent;
}
.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
  transform: scale(1.5);
}
.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-style: solid;
  border-color: #000;
  border-top-width: 0;
  border-bottom-width: 2px;
  border-left-width: 0;
  border-right-width: 2px;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: 0;
}

.PhoneInputCountrySelect {
  padding-left: 5px;
}

.predictions {
  position: absolute;
  top: 100%;
  background: white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  list-style-type: none;
  z-index: 99;
  width: 100%;
  left: 0;
}

.add_user_info .view_icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.new-password-condition {
  display: block;
  text-align: start;
}

.back-arrow {
  position: absolute;
  top: 35px;
  left: 20px;
  background: none;
  border: none;
}
.remove {
  font-weight: 700;
  cursor: pointer;
  float: right;
  margin: 0.5rem 0;
  color: rgb(198, 64, 67);
}

.predictions {
  position: absolute;
  top: 100%;
  background: white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  list-style-type: none;
  z-index: 99;
  width: 100%;
  left: 0;
}

.question-area {
  height: 150px;
}

img.userImage {
  height: 200px;
  width: 201px;
  object-fit: cover;
}
